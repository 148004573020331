import logo from './logo.svg';
import './App.css';
import QuizStart from './QuizStart';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <QuizStart></QuizStart>
      </header>
    </div>
  );
}

export default App;
